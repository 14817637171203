import {formatTableData} from 'utils/util';

const INVOICE_LIST_START = 'zb/INVOICE_LIST_START';
const INVOICE_LIST_SUCCESS = 'zb/INVOICE_LIST_SUCCESS';
const INVOICE_LIST_FAILED = 'zb/INVOICE_LIST_FAILED';

const GET_STATISTICAMOUNT_START = 'zb/GET_STATISTICAMOUNT_START';
const GET_STATISTICAMOUNT_SUCCESS = 'zb/GET_STATISTICAMOUNT_SUCCESS';
const GET_STATISTICAMOUNT_FAILED = 'zb/GET_STATISTICAMOUNT_FAILED';

const EXPORT_MERINVOICE_START = 'zb/EXPORT_MERINVOICE_START';
const EXPORT_MERINVOICE_SUCCESS = 'zb/EXPORT_MERINVOICE_SUCCESS';
const EXPORT_MERINVOICE_FAILED = 'zb/EXPORT_MERINVOICE_FAILED';

let initialState = {
  invoiceList: [],
  total: 0,
  amountInfo: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INVOICE_LIST_SUCCESS:
      return {
        ...state,
        invoiceList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case GET_STATISTICAMOUNT_SUCCESS:
      return {
        ...state,
        amountInfo: action.resp.data
      };
    case EXPORT_MERINVOICE_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getInvoiceList(data = {}) {
  return {
    types: [INVOICE_LIST_START, INVOICE_LIST_SUCCESS, INVOICE_LIST_FAILED],
    promise: (client) => client.get('invoice/list', {
      params: data,
    })
  };
}

// 开票金额统计
export function getStatisticAmount(data = {}) {
  return {
    types: [GET_STATISTICAMOUNT_START, GET_STATISTICAMOUNT_SUCCESS, GET_STATISTICAMOUNT_FAILED],
    promise: (client) => client.get('invoice/statisticAmount', {
      params: data,
    })
  };
}

// 导出excel
export function exportMerInvoice(data = {}) {
  console.log(data);
  return {
    types: [EXPORT_MERINVOICE_START, EXPORT_MERINVOICE_SUCCESS, EXPORT_MERINVOICE_FAILED],
    promise: (client) => client.get('export/merInvoice', {
      params: data,
      downloadFile: {name: '开票列表.xlsx'}
    })
  };
}
