import { formatTableData } from 'utils/util';

const SIGNRECORD_LIST_START = 'zb/SIGNRECORD_LIST_START';
const SIGNRECORD_LIST_SUCCESS = 'zb/SIGNRECORD_LIST_SUCCESS';
const SIGNRECORD_LIST_FAILED = 'zb/SIGNRECORD_LIST_FAILED';

const DOWNLOAD_TEMPLATE_START = 'zb/DOWNLOAD_TEMPLATE_START';
const DOWNLOAD_TEMPLATE_SUCCESS = 'zb/DOWNLOAD_TEMPLATE_SUCCESS';
const DOWNLOAD_TEMPLATE_FAILED = 'zb/DOWNLOAD_TEMPLATE_FAILED';

const EXPORT_EXCEL_START = 'zb/EXPORT_EXCEL_START';
const EXPORT_EXCEL_SUCCESS = 'zb/EXPORT_EXCEL_SUCCESS';
const EXPORT_EXCEL_FAILED = 'zb/EXPORT_EXCEL_FAILED';

const BATCH_IMPORT_START = 'zb/BATCH_IMPORT_START';
const BATCH_IMPORT_SUCCESS = 'zb/BATCH_IMPORT_SUCCESS';
const BATCH_IMPORT_FAILED = 'zb/BATCH_IMPORT_FAILED';

const BATCH_SENDINFOMATIONDATA_START = 'zb/BATCH_SENDINFOMATIONDATA_START';
const BATCH_SENDINFOMATIONDATA_SUCCESS = 'zb/BATCH_SENDINFOMATIONDATA_SUCCESS';
const BATCH_SENDINFOMATIONDATA_FAILED = 'zb/BATCH_SENDINFOMATIONDATA_FAILED';

const ADD_SIGNRECORD_START = 'zb/ADD_SIGNRECORD_START';
const ADD_SIGNRECORD_SUCCESS = 'zb/ADD_SIGNRECORD_SUCCESS';
const ADD_SIGNRECORD_FAILED = 'zb/ADD_SIGNRECORD_FAILED';

const UPDATE_SIGNRECORD_START = 'zb/UPDATE_SIGNRECORD_START';
const UPDATE_SIGNRECORD_SUCCESS = 'zb/UPDATE_SIGNRECORD_SUCCESS';
const UPDATE_SIGNRECORD_FAILED = 'zb/UPDATE_SIGNRECORD_FAILED';


const GET_GETMERCHANTSELECT_START = 'rsd/user/GET_GETMERCHANTSELECT_START';
const GET_GETMERCHANTSELECT_SUCCESS = 'rsd/user/GET_GETMERCHANTSELECT_SUCCESS';
const GET_GETMERCHANTSELECT_FAILED = 'rsd/user/GET_GETMERCHANTSELECT_FAILED';


const GET_TASK_LIST_START = 'zb/GET_TASK_LIST_START';
const GET_TASK_LIST_SUCCESS = 'zb/GET_TASK_LIST_SUCCESS';
const GET_TASK_LIST_FAILED = 'zb/GET_TASK_LIST_FAILED';

const DELETE_SIGNER_START = 'zb/DELETE_SIGNER_START';
const DELETE_SIGNER_SUCCESS = 'zb/DELETE_SIGNER_SUCCESS';
const DELETE_SIGNER_FAILED = 'zb/DELETE_SIGNER_FAILED';

let initialState = {
  signRecordList: [],
  total: 0,
  taskList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SIGNRECORD_LIST_SUCCESS:
      return {
        ...state,
        signRecordList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case DOWNLOAD_TEMPLATE_SUCCESS:
      return {
        ...state
      };
    case EXPORT_EXCEL_SUCCESS:
      return {
        ...state
      };
    case BATCH_IMPORT_SUCCESS:
      return {
        ...state
      };
    case BATCH_SENDINFOMATIONDATA_SUCCESS:
      return {
        ...state
      };
    case ADD_SIGNRECORD_SUCCESS:
      return {
        ...state
      };
    case UPDATE_SIGNRECORD_SUCCESS:
      return {
        ...state
      };
    case GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        taskList: action.resp.data
      };
    case DELETE_SIGNER_SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
}

// 签约记录列表
export function getSignRecordList(data = {}) {
  return {
    types: [SIGNRECORD_LIST_START, SIGNRECORD_LIST_SUCCESS, SIGNRECORD_LIST_FAILED],
    promise: (client) => client.get('soho/list', {
      params: data,
    })
  };
}

// 下载导入模板
export function downloadTemplate(data = {}) {
  return {
    types: [DOWNLOAD_TEMPLATE_START, DOWNLOAD_TEMPLATE_SUCCESS, DOWNLOAD_TEMPLATE_FAILED],
    promise: (client) => client.get('export/soho/template', {
      params: data,
      downloadFile: { name: '签约导入模板.xlsx' }
    })
  };
}

// 导出excel
export function exportExcelApi(data = {}) {
  return {
    types: [EXPORT_EXCEL_START, EXPORT_EXCEL_SUCCESS, EXPORT_EXCEL_FAILED],
    promise: (client) => client.get('export/soho', {
      params: data,
      downloadFile: { name: '签约记录.xlsx' }
    })
  };
}

// 批量导入
export function batchImportApi(data = {}) {
  return {
    types: [BATCH_IMPORT_START, BATCH_IMPORT_SUCCESS, BATCH_IMPORT_FAILED],
    promise: (client) => client.post('import/soho', {
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  };
}
// 信息补充
export function sendInfomationData(data = {}) {
  return {
    types: [BATCH_SENDINFOMATIONDATA_START, BATCH_SENDINFOMATIONDATA_SUCCESS, BATCH_SENDINFOMATIONDATA_FAILED],
    promise: (client) => client.post('import/importSoho', {
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  };
}

// 新增记录
export function addSignRecord(data = {}) {
  return {
    types: [ADD_SIGNRECORD_START, ADD_SIGNRECORD_SUCCESS, ADD_SIGNRECORD_FAILED],
    promise: (client) => client.post('soho/create', {
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  };
}

// 修改记录
export function updateSignRecord(data = {}) {
  return {
    types: [UPDATE_SIGNRECORD_START, UPDATE_SIGNRECORD_SUCCESS, UPDATE_SIGNRECORD_FAILED],
    promise: (client) => client.put('soho/update', {
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  };
}

//获取商户列表
export const getNameMerchantSelect = (data) => {
  return {
    types: [GET_GETMERCHANTSELECT_START, GET_GETMERCHANTSELECT_SUCCESS, GET_GETMERCHANTSELECT_FAILED],
    promise: (client) => {
      return client.get(`bill/charge/merchantList`, {
        params: {
          // ...data
        }
      });
    }
  };
};

// 任务列表-搜索框用
export function getTaskList(data = {}) {
  return {
    types: [GET_TASK_LIST_START, GET_TASK_LIST_SUCCESS, GET_TASK_LIST_FAILED],
    promise: (client) => client.get('job/search', {
      params: data,
    })
  };
}

// 删除已签约的商户
export function deleteSigner(data = {}) {
  return {
    types: [DELETE_SIGNER_START, DELETE_SIGNER_SUCCESS, DELETE_SIGNER_FAILED],
    promise: (client) => client.delete('soho/delete', {
      params: data,
    })
  };
}
