import {formatTableData} from 'utils/util';

const GET_RECORD_DETAIL_LIST_START = 'zb/GET_RECORD_DETAIL_LIST_START';
const GET_RECORD_DETAIL_LIST_SUCCESS = 'zb/GET_RECORD_DETAIL_LIST_SUCCESS';
const GET_RECORD_DETAIL_LIST_FAILED = 'zb/GET_RECORD_DETAIL_LIST_FAILED';

const RECORD_RESUBMIT_START = 'zb/RECORD_RESUBMIT_START';
const RECORD_RESUBMIT_SUCCESS = 'zb/RECORD_RESUBMIT_SUCCESS';
const RECORD_RESUBMIT_FAILED = 'zb/RECORD_RESUBMIT_FAILED';

const RECORD_RESIGN_START = 'zb/RECORD_RESIGN_START';
const RECORD_RESIGN_SUCCESS = 'zb/RECORD_RESIGN_SUCCESS';
const RECORD_RESIGN_FAILED = 'zb/RECORD_RESIGN_FAILED';

const UPDATE_SIGNINFO_START = 'zb/UPDATE_SIGNINFO_START';
const UPDATE_SIGNINFO_SUCCESS = 'zb/UPDATE_SIGNINFO_SUCCESS';
const UPDATE_SIGNINFO_FAILED = 'zb/UPDATE_SIGNINFO_FAILED';

let initialState = {
  recordDetailList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_RECORD_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        recordDetailList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case RECORD_RESUBMIT_SUCCESS:
      return {
        ...state
      };
    case RECORD_RESIGN_SUCCESS:
      return {
        ...state
      };
    case UPDATE_SIGNINFO_SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
}

export function getRecordDetailList(data = {}) {
  return {
    types: [GET_RECORD_DETAIL_LIST_START, GET_RECORD_DETAIL_LIST_SUCCESS, GET_RECORD_DETAIL_LIST_FAILED],
    promise: (client) => client.get('soho/recordList', {
      params: data,
    })
  };
}

// 重新提交
export function recordReSubmit(data = {}) {
  return {
    types: [RECORD_RESUBMIT_START, RECORD_RESUBMIT_SUCCESS, RECORD_RESUBMIT_FAILED],
    promise: (client) => client.get('soho/subSign', {
      params: data,
    })
  };
}

// 重新签约
export function recordReSign(data = {}) {
  return {
    types: [RECORD_RESIGN_START, RECORD_RESIGN_SUCCESS, RECORD_RESIGN_FAILED],
    promise: (client) => client.post('soho/batchSubSign', {
      data: data,
    })
  };
}

// 修改记录
export function updateSignInfo(data = {}) {
  return {
    types: [UPDATE_SIGNINFO_START, UPDATE_SIGNINFO_SUCCESS, UPDATE_SIGNINFO_FAILED],
    promise: (client) => client.put('soho/importRecord/update', {
      data: data,
    })
  };
}