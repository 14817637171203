import {formatTableData} from 'utils/util';

const SUBSIDIARY_LIST_START = 'zb/SUBSIDIARY_LIST_START';
const SUBSIDIARY_LIST_SUCCESS = 'zb/SUBSIDIARY_LIST_SUCCESS';
const SUBSIDIARY_LIST_FAILED = 'zb/SUBSIDIARY_LIST_FAILED';

const SOHO_SYNCSOHO_START = 'zb/SOHO_SYNCSOHO_START';
const SOHO_SYNCSOHO_SUCCESS = 'zb/SOHO_SYNCSOHO_SUCCESS';
const SOHO_SYNCSOHO_FAILED = 'zb/SOHO_SYNCSOHO_FAILED';

const GET_SONMERCHANT_LIST_START = 'zb/GET_SONMERCHANT_LIST_START';
const GET_SONMERCHANT_LIST_SUCCESS = 'zb/GET_SONMERCHANT_LIST_SUCCESS';
const GET_SONMERCHANT_LIST_FAILED = 'zb/GET_SONMERCHANT_LIST_FAILED';

const EXPORT_EXCEL_START = 'zb/EXPORT_EXCEL_START';
const EXPORT_EXCEL_SUCCESS = 'zb/EXPORT_EXCEL_SUCCESS';
const EXPORT_EXCEL_FAILED = 'zb/EXPORT_EXCEL_FAILED';

let initialState = {
  subsidiaryList: [],
  total: 0,
  sonMerchantList: [],
  sohoTotal: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUBSIDIARY_LIST_SUCCESS:
      return {
        ...state,
        subsidiaryList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case SOHO_SYNCSOHO_SUCCESS:
      return {
        ...state,
      };
    case GET_SONMERCHANT_LIST_SUCCESS:
      return {
        ...state,
        sonMerchantList: formatTableData(action.resp.data && action.resp.data.list || []),
        sohoTotal: action.resp.data && action.resp.data.total
      };
    default:
      return state;
  }
}

// 子公司管理列表
export function getSubsidiaryList(data = {}) {
  return {
    types: [SUBSIDIARY_LIST_START, SUBSIDIARY_LIST_SUCCESS, SUBSIDIARY_LIST_FAILED],
    promise: (client) => client.get('merchant/sonMerchant', {
      params: data,
    })
  };
}

// 集团管理-子公司管理-soho同步
export function sohoSyncSoho(data = {}) {
  return {
    types: [SOHO_SYNCSOHO_START, SOHO_SYNCSOHO_SUCCESS, SOHO_SYNCSOHO_FAILED],
    promise: (client) => client.get('soho/syncSoho', {
      params: data,
    })
  };
}

// 集团管理-子公司管理-签约详情
export function getSonMerchantList(data = {}) {
  return {
    types: [GET_SONMERCHANT_LIST_START, GET_SONMERCHANT_LIST_SUCCESS, GET_SONMERCHANT_LIST_FAILED],
    promise: (client) => client.get('soho/sonMerchant/list', {
      params: data,
    })
  };
}

// 导出excel
export function exportExcelApi(data = {}) {
  return {
    types: [EXPORT_EXCEL_START, EXPORT_EXCEL_SUCCESS, EXPORT_EXCEL_FAILED],
    promise: (client) => client.get('export/group/soho', {
      params: data,
      downloadFile: {name: '签约记录.xlsx'}
    })
  };
}


