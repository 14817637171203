import {formatTableData} from 'utils/util';

const GET_TASK_LIST_START = 'zb/GET_TASK_LIST_START';
const GET_TASK_LIST_SUCCESS = 'zb/GET_TASK_LIST_SUCCESS';
const GET_TASK_LIST_FAILED = 'zb/GET_TASK_LIST_FAILED';

const GET_SOHOJOB_LIST_START = 'zb/GET_SOHOJOB_LIST_START';
const GET_SOHOJOB_LIST_SUCCESS = 'zb/GET_SOHOJOB_LIST_SUCCESS';
const GET_SOHOJOB_LIST_FAILED = 'zb/GET_SOHOJOB_LIST_FAILED';

const JOB_CHANGESTATE_START = 'zb/JOB_CHANGESTATE_START';
const JOB_CHANGESTATE_SUCCESS = 'zb/JOB_CHANGESTATE_SUCCESS';
const JOB_CHANGESTATE_FAILED = 'zb/JOB_CHANGESTATE_FAILED';

let initialState = {
  taskList: [],
  total: 0,
  sohojobList: [],
  sohojobTotal: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        taskList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case GET_SOHOJOB_LIST_SUCCESS:
      return {
        ...state,
        sohojobList: formatTableData(action.resp.data && action.resp.data.list || []),
        sohojobTotal: action.resp.data && action.resp.data.total
      };
    case JOB_CHANGESTATE_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// 签约记录列表
export function getTaskList(data = {}) {
  return {
    types: [GET_TASK_LIST_START, GET_TASK_LIST_SUCCESS, GET_TASK_LIST_FAILED],
    promise: (client) => client.get('job/jobList', {
      params: data,
    })
  };
}

// 签约记录列表
export function getSohoJobList(data = {}) {
  return {
    types: [GET_SOHOJOB_LIST_START, GET_SOHOJOB_LIST_SUCCESS, GET_SOHOJOB_LIST_FAILED],
    promise: (client) => client.get('job/jobSoho/list', {
      params: data,
    })
  };
}

// 确认信息
export function changeJobState(data = {}) {
  return {
    types: [JOB_CHANGESTATE_START, JOB_CHANGESTATE_SUCCESS, JOB_CHANGESTATE_FAILED],
    promise: (client) => client.post('job/jobSoho/changeState', {
      data: data,
    })
  };
}


