const AUTHENTICATION_START = 'zb/AUTHENTICATION_START';
const AUTHENTICATION_SUCCESS = 'zb/AUTHENTICATION_SUCCESS';
const AUTHENTICATION_FAILED = 'zb/AUTHENTICATION_FAILED';

const VALIDURL_START = 'zb/VALIDURL_START';
const VALIDURL_SUCCESS = 'zb/VALIDURL_SUCCESS';
const VALIDURL_FAILED = 'zb/VALIDURL_FAILED';

const MODIFYPWD_START = 'zb/MODIFYPWD_START';
const MODIFYPWD_SUCCESS = 'zb/MODIFYPWD_SUCCESS';
const MODIFYPWD_FAILED = 'zb/MODIFYPWD_FAILED';

const MODIFYUSERPWD_START = 'zb/MODIFYUSERPWD_START';
const MODIFYUSERPWD_SUCCESS = 'zb/MODIFYUSERPWD_SUCCESS';
const MODIFYUSERPWD_FAILED = 'zb/MODIFYUSERPWD_FAILED';

let initialState = {

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
      };
    case VALIDURL_SUCCESS:
      return {
        ...state,
      };
    case MODIFYPWD_SUCCESS:
      return {
        ...state,
      };
    case MODIFYUSERPWD_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function authentication(data) {
  return {
    types: [AUTHENTICATION_START, AUTHENTICATION_SUCCESS, AUTHENTICATION_FAILED],
    promise: (client) => client.get('user/findPwd', {
      params: data,
      hasToken: false
    })
  };
}

export function validUrl(data) {
  return {
    types: [VALIDURL_START, VALIDURL_SUCCESS, VALIDURL_FAILED],
    promise: (client) => client.get('user/findPwd/validUrl', {
      params: data,
      hasToken: false
    })
  };
}

export function modifyPwd(data) {
  return {
    types: [MODIFYPWD_START, MODIFYPWD_SUCCESS, MODIFYPWD_FAILED],
    promise: (client) => client.post('user/findPwd/modify', {
      data: data,
      hasToken: false
    })
  };
}

export function modifyUserPwd(data) {
  return {
    types: [MODIFYUSERPWD_START, MODIFYUSERPWD_SUCCESS, MODIFYUSERPWD_FAILED],
    promise: (client) => client.post('user/modifyPwd', {
      data: data,
    })
  };
}
