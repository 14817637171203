import { combineReducers } from 'redux';
import login from 'routes/BasicModule/Login/reducer';
import common from './common';
import roleManage from 'routes/MerchantCenter/AuthorityManage/RoleManage/reducer';
import userManage from 'routes/MerchantCenter/AuthorityManage/UserManage/reducer';
import forgetPassword from 'routes/BasicModule/RetrievePassword/reducer';
import signRecord from 'routes/MerchantCenter/Professional/SignRecord/reducer';
import addRecord from 'routes/MerchantCenter/Professional/AddRecord/reducer';
import recordDetail from 'routes/MerchantCenter/Professional/RecordDetail/reducer';
import rechargeRecord from 'routes/TradingCenter/AccountRecharge/RechargeRecord/reducer';
import rechargeEntry from 'routes/TradingCenter/AccountRecharge/RechargeEntry/reducer';
import accountBalance from 'routes/TradingCenter/AccountRecharge/AccountBalance/reducer';
import applyInvoice from 'routes/FinancialCenter/InvoiceCenter/ApplyInvoice/reducer';
import invoiceInquiry from 'routes/FinancialCenter/InvoiceCenter/InvoiceInquiry/reducer';
import payUploadRecord from 'routes/TradingCenter/EntrustedSettle/PayUploadRecord/reducer';
import uploadFirstTrial from 'routes/TradingCenter/EntrustedSettle/UploadFirstTrial/reducer';
import uploadReview from 'routes/TradingCenter/EntrustedSettle/UploadReview/reducer';
import paymentRecords from 'routes/TradingCenter/EntrustedSettle/PaymentRecords/reducer';
import merchantInfo from 'routes/MerchantCenter/MerchantManage/MerchantInfo/reducer';
import customContract from 'routes/MerchantCenter/MerchantManage/CustomContract/reducer';
import contractSett from 'routes/MerchantCenter/MerchantManage/ContractSett/reducer';
import rechargeReceipt from 'routes/MerchantCenter/MerchantManage/RechargeReceipt/reducer';
import subsidiary from 'routes/MerchantCenter/GroupManage/SubsidiaryManage/reducer';
import bulkPayment from 'routes/TradingCenter/EntrustedSettle/BulkPayment/reducer';
import accountFlow from 'routes/FinancialCenter/AccountFinance/AccountFlow/reducer';
import financeBalance from 'routes/FinancialCenter/AccountFinance/FinanceBalance/reducer';
import serviceNotice from 'routes/InformationCenter/AnnounceInfo/ServiceNotice/reducer';
import home from 'routes/BasicModule/Home/reducer';
import paymentHistory from 'routes/TradingCenter/EntrustedSettle/PaymentHistory/reducer';
import paymentPassword from 'routes/MerchantCenter/SecuritySetting/PaymentPassword/reducer';
import templateList from 'routes/MerchantCenter/TaskManage/TemplateList/reducer';
import taskList from 'routes/MerchantCenter/TaskManage/TaskList/reducer';
import billAddress from 'routes/FinancialCenter/InvoiceCenter/BillAddress/reducer';
import publishTask from 'routes/MerchantCenter/TaskManage/PublishTask/reducer';
import technologyDock from 'routes/MerchantCenter/SecuritySetting/TechnologyDock/reducer';

export default combineReducers({
  common,
  login,
  roleManage,
  userManage,
  forgetPassword,
  signRecord,
  addRecord,
  recordDetail,
  rechargeRecord,
  rechargeEntry,
  accountBalance,
  applyInvoice,
  invoiceInquiry,
  payUploadRecord,
  uploadFirstTrial,
  uploadReview,
  paymentRecords,
  merchantInfo,
  customContract,
  contractSett,
  rechargeReceipt,
  subsidiary,
  bulkPayment,
  accountFlow,
  financeBalance,
  serviceNotice,
  home,
  paymentHistory,
  paymentPassword,
  templateList,
  taskList,
  billAddress,
  publishTask,
  technologyDock
})