import {formatTableData} from 'utils/util';

const GET_BATCH_LIST_START = 'zb/GET_BATCH_LIST_START';
const GET_BATCH_LIST_SUCCESS = 'zb/GET_BATCH_LIST_SUCCESS';
const GET_BATCH_LIST_FAILED = 'zb/GET_BATCH_LIST_FAILED';

const GET_BILL_INFO_START = 'zb/GET_BILL_INFO_START';
const GET_BILL_INFO_SUCCESS = 'zb/GET_BILL_INFO_SUCCESS';
const GET_BILL_INFO_FAILED = 'zb/GET_BILL_INFO_FAILED';

const CREATE_INVOICE_START = 'zb/CREATE_INVOICE_START';
const CREATE_INVOICE_SUCCESS = 'zb/CREATE_INVOICE_SUCCESS';
const CREATE_INVOICE_FAILED = 'zb/CREATE_INVOICE_FAILED';

const POST_INVOICE_AMOUNT_START = 'zb/POST_INVOICE_AMOUNT_START';
const POST_INVOICE_AMOUNT_SUCCESS = 'zb/POST_INVOICE_AMOUNT_SUCCESS';
const POST_INVOICE_AMOUNT_FAILED = 'zb/POST_INVOICE_AMOUNT_FAILED';


let initialState = {
  batchList: [],
  total: 0,
  billInfo: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_BATCH_LIST_SUCCESS:
      return {
        ...state,
        batchList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case GET_BILL_INFO_SUCCESS:
      return {
        ...state,
        billInfo: action.resp.data
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
      };
    case POST_INVOICE_AMOUNT_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// 开票批次列表
export function getBatchList(data = {}) {
  return {
    types: [GET_BATCH_LIST_START, GET_BATCH_LIST_SUCCESS, GET_BATCH_LIST_FAILED],
    promise: (client) => client.get('invoice/batch', {
      params: data,
    })
  };
}

// 获取开票信息
export function getBillInfo(data = {}) {
  return {
    types: [GET_BILL_INFO_START, GET_BILL_INFO_SUCCESS, GET_BILL_INFO_FAILED],
    promise: (client) => client.get('invoice/search', {
      params: data,
    })
  };
}

// 开票提交
export function createInvoice(data = {}) {
  return {
    types: [CREATE_INVOICE_START, CREATE_INVOICE_SUCCESS, CREATE_INVOICE_FAILED],
    promise: (client) => client.post('invoice/create', {
      data: data,
    })
  };
}

// 勾选批次计算总额
export function postInvoiceAmount(data = {}) {
  return {
    types: [POST_INVOICE_AMOUNT_START, POST_INVOICE_AMOUNT_SUCCESS, POST_INVOICE_AMOUNT_FAILED],
    promise: (client) => client.post('invoice/amount', {
      data: data,
    })
  };
}

