import {formatTableData} from 'utils/util';

const GET_HSERVICENOTICE_LIST_START = 'zb/GET_HSERVICENOTICE_LIST_START';
const GET_HSERVICENOTICE_LIST_SUCCESS = 'zb/GET_HSERVICENOTICE_LIST_SUCCESS';
const GET_HSERVICENOTICE_LIST_FAILED = 'zb/GET_HSERVICENOTICE_LIST_FAILED';

const GET_FLOW_WORKBENCH_START = 'zb/GET_FLOW_WORKBENCH_START';
const GET_FLOW_WORKBENCH_SUCCESS = 'zb/GET_FLOW_WORKBENCH_SUCCESS';
const GET_FLOW_WORKBENCH_FAILED = 'zb/GET_FLOW_WORKBENCH_FAILED';

const GET_SOHO_STATISTIC_START = 'zb/GET_SOHO_STATISTIC_START';
const GET_SOHO_STATISTIC_SUCCESS = 'zb/GET_SOHO_STATISTIC_SUCCESS';
const GET_SOHO_STATISTIC_FAILED = 'zb/GET_SOHO_STATISTIC_FAILED';

const GET_INVOICE_STATISTIC_START = 'zb/GET_INVOICE_STATISTIC_START';
const GET_INVOICE_STATISTIC_SUCCESS = 'zb/GET_INVOICE_STATISTIC_SUCCESS';
const GET_INVOICE_STATISTIC_FAILED = 'zb/GET_INVOICE_STATISTIC_FAILED';

let initialState = {

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_HSERVICENOTICE_LIST_SUCCESS:
      return {
        ...state
      };
    case GET_FLOW_WORKBENCH_SUCCESS:
      return {
        ...state
      };
    case GET_SOHO_STATISTIC_SUCCESS:
      return {
        ...state
      };
    case GET_INVOICE_STATISTIC_SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
}

export function getServiceHNoticeList(data = {}) {
  return {
    types: [GET_HSERVICENOTICE_LIST_START, GET_HSERVICENOTICE_LIST_SUCCESS, GET_HSERVICENOTICE_LIST_FAILED],
    promise: (client) => client.get('notify/info', {
      params: data,
    })
  };
}

// 商户工作台-统计数据
export function getFlowWorkbench(data = {}) {
  return {
    types: [GET_FLOW_WORKBENCH_START, GET_FLOW_WORKBENCH_SUCCESS, GET_FLOW_WORKBENCH_FAILED],
    promise: (client) => client.get('bill/flow/workbench', {
      params: data,
    })
  };
}

// 自由职业者统计
export function getSohoStatistic(data = {}) {
  return {
    types: [GET_SOHO_STATISTIC_START, GET_SOHO_STATISTIC_SUCCESS, GET_SOHO_STATISTIC_FAILED],
    promise: (client) => client.get('soho/home/statistic', {
      params: data,
    })
  };
}

// 开票金额统计
export function getInvoiceStatistic(data = {}) {
  return {
    types: [GET_INVOICE_STATISTIC_START, GET_INVOICE_STATISTIC_SUCCESS, GET_INVOICE_STATISTIC_FAILED],
    promise: (client) => client.get('invoice/statisticAmount', {
      params: data,
    })
  };
}
