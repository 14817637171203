import {formatTableData} from 'utils/util';

const GET_FIRSTTRIAL_LIST_START = 'zb/GET_FIRSTTRIAL_LIST_START';
const GET_FIRSTTRIAL_LIST_SUCCESS = 'zb/GET_FIRSTTRIAL_LIST_SUCCESS';
const GET_FIRSTTRIAL_LIST_FAILED = 'zb/GET_FIRSTTRIAL_LIST_FAILED';

const SETTLE_EXAMINE_START = 'zb/SETTLE_EXAMINE_START';
const SETTLE_EXAMINE_SUCCESS = 'zb/SETTLE_EXAMINE_SUCCESS';
const SETTLE_EXAMINE_FAILED = 'zb/SETTLE_EXAMINE_FAILED';

let initialState = {
  firstTrialList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FIRSTTRIAL_LIST_SUCCESS:
      return {
        ...state,
        firstTrialList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case SETTLE_EXAMINE_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getFirstTrialList(data = {}) {
  return {
    types: [GET_FIRSTTRIAL_LIST_START, GET_FIRSTTRIAL_LIST_SUCCESS, GET_FIRSTTRIAL_LIST_FAILED],
    promise: (client) => client.get('finance/settle/list', {
      params: data,
    })
  };
}

// 上传初审接口
export function settleExamine(data = {}) {
  return {
    types: [SETTLE_EXAMINE_START, SETTLE_EXAMINE_SUCCESS, SETTLE_EXAMINE_FAILED],
    promise: (client) => client.post('finance/settle/examine', {
      data: data,
    })
  };
}
