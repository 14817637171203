import { createStore, applyMiddleware,compose } from 'redux';
import createMiddleware from './clientMiddleware';
import client from './apiClient';
import reducer from './reducers';

const middleware = [createMiddleware(client)];
const store = createStore(
  reducer,
  compose(applyMiddleware(...middleware))
);

export default store;