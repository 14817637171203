import {formatTableData} from 'utils/util';

const GET_REVIEW_LIST_START = 'zb/GET_REVIEW_LIST_START';
const GET_REVIEW_LIST_SUCCESS = 'zb/GET_REVIEW_LIST_SUCCESS';
const GET_REVIEW_LIST_FAILED = 'zb/GET_REVIEW_LIST_FAILED';

const SETTLE_REEXAMINE_START = 'zb/SETTLE_REEXAMINE_START';
const SETTLE_REEXAMINE_SUCCESS = 'zb/SETTLE_REEXAMINE_SUCCESS';
const SETTLE_REEXAMINE_FAILED = 'zb/SETTLE_REEXAMINE_FAILED';

let initialState = {
  reviewList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        reviewList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case SETTLE_REEXAMINE_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getReviewList(data = {}) {
  return {
    types: [GET_REVIEW_LIST_START, GET_REVIEW_LIST_SUCCESS, GET_REVIEW_LIST_FAILED],
    promise: (client) => client.get('finance/settle/list', {
      params: data,
    })
  };
}

// 上传复核接口
export function settleReExamine(data = {}) {
  return {
    types: [SETTLE_REEXAMINE_START, SETTLE_REEXAMINE_SUCCESS, SETTLE_REEXAMINE_FAILED],
    promise: (client) => client.post('finance/settle/reexamine', {
      data: data,
    })
  };
}
// 上传复核接口
export function messageCodeSwitch(data = {}) {
  return {
    types: [SETTLE_REEXAMINE_START, SETTLE_REEXAMINE_SUCCESS, SETTLE_REEXAMINE_FAILED],
    promise: (client) => client.get('finance/settle/queryMessageCode')
  };
}
