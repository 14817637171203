import {formatTableData} from 'utils/util';

const TEMPLATE_LIST_START = 'zb/TEMPLATE_LIST_START';
const TEMPLATE_LIST_SUCCESS = 'zb/TEMPLATE_LIST_SUCCESS';
const TEMPLATE_LIST_FAILED = 'zb/TEMPLATE_LIST_FAILED';

const TEMPLATE_DETAIL_START = 'zb/TEMPLATE_DETAIL_START';
const TEMPLATE_DETAIL_SUCCESS = 'zb/TEMPLATE_DETAIL_SUCCESS';
const TEMPLATE_DETAIL_FAILED = 'zb/TEMPLATE_DETAIL_FAILED';

let initialState = {
  templateList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        templateList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case TEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// 模板列表查询
export function getTemplateList(data = {}) {
  return {
    types: [TEMPLATE_LIST_START, TEMPLATE_LIST_SUCCESS, TEMPLATE_LIST_FAILED],
    promise: (client) => client.get('job/templateList', {
      params: data,
    })
  };
}

// 模板详情查询
export function getTemplateDetail(data = {}) {
  return {
    types: [TEMPLATE_DETAIL_START, TEMPLATE_DETAIL_SUCCESS, TEMPLATE_DETAIL_FAILED],
    promise: (client) => client.get('job/templateDetail', {
      params: data,
    })
  };
}

