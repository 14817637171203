import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getMenuList} from 'reduxs/common';
import {Layout} from 'antd';
import SiderPage from './Sider';
import HeaderPage from './Header';
import {getBreadcrumbList} from 'utils/util';
import './index.less';

const {Header, Content, Sider} = Layout;

function MainLayout(props) {

    const [menuList, setMenuList] = useState([
        {
            "id": 77,
            "menu": "个体户注册",
            "channel": "type2",
            "icon": "",
            "type": 1,
            "typeDesc": "根目录",
            "parentId": 0,
            "url": "",
            "sort": 1,
            "ifShow": true,
            "updaterId": "",
            "updater": "",
            "gmtCreate": 1600343233000,
            "gmtModified": 1611542266000,
            "children": [
                {
                    "id": 80,
                    "menu": "信息收集",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 77,
                    "url": "/merchantCenter/temporarylogin/temporaryperson",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 80,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 81,
                    "menu": "实名认证",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 77,
                    "url": "",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 81,
                    "menuIndex": "根目录/二级目录"
                },
            ],
            "key": 77,
            "menuIndex": "根目录"
        },
        {
            "id": 78,
            "menu": "灵工管理",
            "channel": "type2",
            "icon": "",
            "type": 1,
            "typeDesc": "根目录",
            "parentId": 0,
            "url": "",
            "sort": 2,
            "ifShow": true,
            "updaterId": "",
            "updater": "",
            "gmtCreate": 1600343233000,
            "gmtModified": null,
            "children": [
                {
                    "id": 95,
                    "menu": "人员管理",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 78,
                    "url": "/merchantCenter/professional/signRecord",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 95,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 379,
                    "menu": "人力管理",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 78,
                    "url": "/merchantCenter/professional/addRecord",
                    "sort": 3,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 379,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 380,
                    "menu": "财务管理",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 78,
                    "url": "/financialCenter/invoiceCenter/applyInvoice",
                    "sort": 3,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 3791,
                    "menuIndex": "根目录/二级目录"
                },
            ],
            "key": 78,
            "menuIndex": "根目录"
        },
        {
            "id": 79,
            "menu": "任务发布",
            "channel": "type2",
            "icon": "",
            "type": 1,
            "typeDesc": "根目录",
            "parentId": 0,
            "url": "",
            "sort": 2,
            "ifShow": true,
            "updaterId": "",
            "updater": "",
            "gmtCreate": 1600343233000,
            "gmtModified": null,
            "children": [
                {
                    "id": 1001,
                    "menu": "员工签约",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 79,
                    "url": "",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 951,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 1002,
                    "menu": "承揽任务",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 79,
                    "url": "",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 96,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 1003,
                    "menu": "验收结算",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 79,
                    "url": "",
                    "sort": 3,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "children": [
                        {
                            "id": 10011,
                            "menu": "查看收入、对账",
                            "channel": "type2",
                            "icon": "",
                            "type": 3,
                            "typeDesc": "菜单",
                            "parentId": 79,
                            "url": "/merchantCenter/professional/signRecord",
                            "sort": 1,
                            "ifShow": true,
                            "updaterId": "",
                            "updater": "",
                            "gmtCreate": 1600343233000,
                            "gmtModified": null,
                            "children": null,
                            "key": 87,
                            "menuIndex": "根目录/二级目录/菜单"
                        }],
                    "key": 379,
                    "menuIndex": "根目录/二级目录"
                },
            ],
            "key": 79,
            "menuIndex": "根目录"
        },
        {
            "id": 80,
            "menu": "部门分组",
            "channel": "type2",
            "icon": "",
            "type": 1,
            "typeDesc": "根目录",
            "parentId": 0,
            "url": "",
            "sort": 2,
            "ifShow": true,
            "updaterId": "",
            "updater": "",
            "gmtCreate": 1600343233000,
            "gmtModified": null,
            "children": [
                {
                    "id": 1001,
                    "menu": "一组",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 80,
                    "url": "",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 952,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 1002,
                    "menu": "二组",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 80,
                    "url": "",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 952,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 1003,
                    "menu": "三组",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 80,
                    "url": "",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 952,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 1004,
                    "menu": "修改信息",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 80,
                    "url": "",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 96,
                    "menuIndex": "根目录/二级目录"
                },
            ],
            "key": 80,
            "menuIndex": "根目录"
        },
        {
            "id": 81,
            "menu": "支付结算",
            "channel": "type2",
            "icon": "",
            "type": 1,
            "typeDesc": "根目录",
            "parentId": 1,
            "url": "",
            "sort": 1,
            "ifShow": true,
            "updaterId": "",
            "updater": "",
            "gmtCreate": 1600343233000,
            "gmtModified": 1611542266000,
            "children": [
                {
                    "id": 10011,
                    "menu": "部门一",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 81,
                    "url": "",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 952,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 10021,
                    "menu": "部门二",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 81,
                    "url": "",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 952,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 10031,
                    "menu": "部门三",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 81,
                    "url": "",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 952,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 10041,
                    "menu": "收款中心",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 81,
                    "url": "/tradingCenter/accountRecharge/rechargeEntry",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 963,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 10041,
                    "menu": "付款中心",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 81,
                    "url": "/tradingCenter/entrustedSettle/bulkPayment",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 962,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 10042,
                    "menu": "结算数据导出",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 81,
                    "url": "/tradingCenter/entrustedSettle/paymentHistory",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 961,
                    "menuIndex": "根目录/二级目录"
                },
            ],
            "key": 81,
            "menuIndex": "根目录"
        },
        {
            "id": 82,
            "menu": "保险购买",
            "channel": "type2",
            "icon": "",
            "type": 1,
            "typeDesc": "根目录",
            "parentId": 0,
            "url": "",
            "sort": 1,
            "ifShow": true,
            "updaterId": "",
            "updater": "",
            "gmtCreate": 1600343233000,
            "gmtModified": 1611542266000,
            "children": [
                {
                    "id": 1001,
                    "menu": "雇主责任险",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 82,
                    "url": "/merchantCenter/merchantManage/merchantInfo",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 953,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 1003,
                    "menu": "企业健康福利",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 82,
                    "url": "/merchantCenter/merchantManage/signCode",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 96,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 1004,
                    "menu": "实时购买商业险",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 82,
                    "url": "/merchantCenter/merchantManage/contractSett",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 961,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 1005,
                    "menu": "汇总清单",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 82,
                    "url": "/merchantCenter/merchantManage/rechargeReceipt",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 964,
                    "menuIndex": "根目录/二级目录"
                },
            ],
            "key": 82,
            "menuIndex": "根目录"
        },
        {
            "id": 83,
            "menu": "技术接口",
            "channel": "type2",
            "icon": "",
            "type": 1,
            "typeDesc": "根目录",
            "parentId": 0,
            "url": "",
            "sort": 1,
            "ifShow": true,
            "updaterId": "",
            "updater": "",
            "gmtCreate": 1600343233000,
            "gmtModified": 1611542266000,
            "children": [
                {
                    "id": 10011,
                    "menu": "数据对接",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 83,
                    "url": "/merchantCenter/authorityManage/userManage",
                    "sort": 1,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 953,
                    "menuIndex": "根目录/二级目录"
                },
                {
                    "id": 10031,
                    "menu": "其他数据对接",
                    "channel": "type2",
                    "icon": "",
                    "type": 2,
                    "typeDesc": "二级目录",
                    "parentId": 83,
                    "url": "/merchantCenter/merchantManage/infomationCode",
                    "sort": 2,
                    "ifShow": true,
                    "updaterId": "",
                    "updater": "",
                    "gmtCreate": 1600343233000,
                    "gmtModified": null,
                    "key": 96,
                    "menuIndex": "根目录/二级目录"
                },
            ],
            "key": 83,
            "menuIndex": "根目录"
        },
        {
            "id": 84,
            "menu": "信息修改",
            "channel": "type2",
            "icon": "",
            "type": 1,
            "typeDesc": "根目录",
            "parentId": 0,
            "url": "/informationCenter/announceInfo/serviceNotice",
            "sort": 1,
            "ifShow": true,
            "updaterId": "",
            "updater": "",
            "gmtCreate": 1600343233000,
            "gmtModified": 1611542266000,
            "key": 84,
            "menuIndex": "根目录"
        },
    ]);

    useEffect(() => {
        props.getMenuList();
    }, []);

    // useEffect(() => {

    //   localStorage.setItem('menuList', JSON.stringify(props.apiMenuList));
    // JSON.parse(localStorage.getItem("menuList")) || []

    // },[props.apiMenuList]);

    return (
        <div className="mainlayout_page">
            <Layout>
                <Sider
                    theme="light"
                    style={{
                        overflowY: 'overlay',
                        overflowX: 'hidden',
                        height: '100%',
                        position: 'fixed',
                        left: 0,
                    }}
                    width={240}
                >
                    <SiderPage {...props} menuList={menuList}></SiderPage>
                </Sider>
                <Layout style={{marginLeft: 240}}>
                    <Header>
                        <HeaderPage {...props} menuList={menuList}></HeaderPage>
                    </Header>
                    <Content>
                        <div className="layout_content">
                            {props.children}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default connect(state => ({
    apiMenuList: state.common.menuList
}), {
    getMenuList
})(MainLayout);
