import {formatTableData} from 'utils/util';

const ACCOUNTBALANCE_LIST_START = 'zb/ACCOUNTBALANCE_LIST_START';
const ACCOUNTBALANCE_LIST_SUCCESS = 'zb/ACCOUNTBALANCE_LIST_SUCCESS';
const ACCOUNTBALANCE_LIST_FAILED = 'zb/ACCOUNTBALANCE_LIST_FAILED';

let initialState = {
  accountBalanceList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACCOUNTBALANCE_LIST_SUCCESS:
      return {
        ...state,
        accountBalanceList: formatTableData(action.resp.data || []),
      };
    default:
      return state;
  }
}

export function getAccountBalanceList(data = {}) {
  return {
    types: [ACCOUNTBALANCE_LIST_START, ACCOUNTBALANCE_LIST_SUCCESS, ACCOUNTBALANCE_LIST_FAILED],
    promise: (client) => client.get('bill/charge/balance', {
      params: data,
    })
  };
}
