import React,{Suspense,lazy} from 'react';
import {Spin} from 'antd';

export default function asyncLoader(importComponent){
  class AsyncComponent extends React.Component{
    constructor(props){
      super(props);
    }
    render(){
      const WrappedComponent = lazy(importComponent);
      return (
        <Suspense fallback={<div className="asyncLoader_spin"><Spin size="large" /></div>}>
          <WrappedComponent {...this.props}></WrappedComponent>
        </Suspense>
      )
    }
  }

  return AsyncComponent;
}