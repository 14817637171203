import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import store from 'reduxs';
import { routes } from 'routes';
import { Login, RetrievePassword } from 'routes/BasicModule';
import MainLayout from './layout';
import 'moment/locale/zh-cn';
import './App.less';

function App() {

  const renderChildren = (route, { match }) => {
    return (
      <div>
        {route.map((item, index) => <Route key={index} path={`${match.url}/${item.path}`} component={item.component} />)}
      </div>
    )
  }

  const createRoutes = (routes) => {
    return routes.reduce((preRoutes, route, i) => {
      if (route.routes && route.routes.length) {
        return [
          ...preRoutes,
          <Route key={i} path={route.path} exact={route.exact} component={renderChildren.bind(null, route.routes)} />
        ]
      } else {
        return [
          ...preRoutes,
          <Route key={i} path={route.path} exact={route.exact} render={props => (<route.component {...props} />)} />
        ];
      }
    }, [])
  }

  //处理语言切换
  let localeType = zhCN;

  return (
    <Provider store={store}>
      <ConfigProvider locale={localeType}>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/" exact component={Login}></Route>
              <Route path="/retrievePassword" exact component={RetrievePassword}></Route>
              <MainLayout>
                {createRoutes(routes)}
              </MainLayout>
            </Switch>
          </Router>
        </div>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
