import React,{useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import {modifyUserPwd} from 'routes/BasicModule/RetrievePassword/reducer';
import {getCurrentBreadcrumb,passwordSReg} from 'utils/util';
import { Menu,Breadcrumb,Modal,Button,Form,Input,Icon,message } from 'antd';
const { SubMenu } = Menu;

function HeaderPage(props){

  const [visible,setVisible] = useState(false);
  const [breadcrumbList,setBreadcrumbList] = useState([]);
  const [timeInterval,setTimeInterval] = useState('早上');
  let history = useHistory();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    if(props.menuList && (props.menuList).length > 0){
      const breadcrumbData = getCurrentBreadcrumb(props.location.pathname,props.menuList).breadcrumb;
      setBreadcrumbList(breadcrumbData);
    }
  },[props.location.pathname,props.menuList]);

  const submit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, fieldsValue) => {
      if(!err){
        if(fieldsValue.newPassword !== fieldsValue.surePassword){
          message.warning("两次密码输入不一致");
          return;
        }
        const postData = {oldPwd: fieldsValue.oldPassword,newPwd: fieldsValue.newPassword};
        const {actionPromise} = props.modifyUserPwd(postData);
        actionPromise.then(res => {
          message.success("修改成功");
          setVisible(false);
          props.form.resetFields();
        })
      }
    });
  }

  useEffect(() => {
    let format = 'hh:mm:ss';
    let nowTime = moment(new Date(),format);
    if(nowTime.isBetween(moment('05:31:00',format),moment('09:00:00',format))){
      setTimeInterval('早上');
    }else if(nowTime.isBetween(moment('09:01:00',format),moment('12:00:00',format))){
      setTimeInterval('上午');
    }else if(nowTime.isBetween(moment('12:01:00',format),moment('14:00:00',format))){
      setTimeInterval('中午');
    }else if(nowTime.isBetween(moment('14:01:00',format),moment('18:30:00',format))){
      setTimeInterval('下午');
    }else if(nowTime.isBetween(moment('18:31:00',format),moment('24:00:00',format))){
      setTimeInterval('晚上');
    }else if(nowTime.isBetween(moment('00:01:00',format),moment('05:30:00',format))){
      setTimeInterval('晚上');
    }
  })

  const modifyPassword = () => {
    setVisible(true);
  }

  const handleCancel = () => {
    setVisible(false);
  };

  const quit = () => {
    localStorage.clear();
    history.replace('/');
  }

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 5, span: 16 },
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className="header_container">
      <div className="header_top">
        <div className="header_breadcrumb">
          <Breadcrumb>
            {
              breadcrumbList.map(b => {
                return <Breadcrumb.Item key={b.key}>{b.title}</Breadcrumb.Item>
              })
            }
          </Breadcrumb>
        </div>
        <div className="header_user_handle">
          <Menu selectedKeys={[]} mode="horizontal">
            <SubMenu title={
              <span className="submenu-title-wrapper">
                {timeInterval}好，{userInfo && userInfo.nickName || ''}
                <Icon type="caret-down" />
              </span>
            }>
              <Menu.Item key="modifyPw" onClick={modifyPassword}>修改密码</Menu.Item>
              <Menu.Item key="quit" onClick={quit}>退出</Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </div>
      <Modal maskClosable={false}
        visible={visible}
        title="密码修改"
        onCancel={handleCancel}
        footer={[]}
        destroyOnClose={true}
      >
        <div className="modify_pwd_modal">
          <Form onSubmit={submit} {...layout}>
            <Form.Item label="原密码">
              {
                getFieldDecorator('oldPassword', { initialValue: '', rules: [{ required: true, message: '请输入原密码' }], })(
                  <Input.Password type="password" />
                )
              }
            </Form.Item>
            <Form.Item label="新密码">
              {
                getFieldDecorator('newPassword', { initialValue: '', rules: [{ required: true, message: '必须包含数字、字母、特殊字符两种或以上混合且长度为4-20位',pattern: passwordSReg }], })(
                  <Input.Password type="password" />
                )
              }
            </Form.Item>
            <Form.Item label="确认密码">
              {
                getFieldDecorator('surePassword', { initialValue: '', rules: [{ required: true, message: '必须包含数字、字母、特殊字符两种或以上混合且长度为4-20位',pattern: passwordSReg }], })(
                  <Input.Password type="password" />
                )
              }
            </Form.Item>
            <div className="modal_form_btn">
              <Button className="modal_btn_cancel" onClick={handleCancel}>取消</Button>
              <Button type="primary" htmlType="submit" className="modal_btn_confirm">确认</Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}


export default connect(state => ({

}),{
  modifyUserPwd
})(Form.create()(HeaderPage));
