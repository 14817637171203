import {
  RoleManage,
  UserManage,
  CooperationInfo,
  MerchantInfo,
  PaymentPassword,
  TechnologyDock,
  SignRecord,
  AddRecord,
  RecordDetail,
  TemporaryLogin,
  TemporaryFailLog,
  TaskList,
  CustomContract,
  SubsidiaryManage,
  SignCode,
  ContractSett,
  RechargeReceipt,
  InfomationCode,
  PublishTask,
  TemplateList,
  TemplateDetail,
  RecruitmentInfo
} from 'routes/MerchantCenter';

import {
  ApplyInvoice,
  InvoiceInquiry,
  AccountFlow,
  FinanceBalance,
  BillAddress
} from 'routes/FinancialCenter';

import {
  BulkPayment,
  Payment,
  PaymentRecords,
  PayUploadRecord,
  UploadList,
  UploadFirstTrial,
  UploadTrial,
  UploadReview,
  CashUploadReview,
  AccountBalance,
  RechargeEntry,
  RechargeRecord,
  PaymentDetail,
  WithdrawalRecord,
  PaymentHistory
} from 'routes/TradingCenter';

import { Home } from 'routes/BasicModule';

import {
  ServiceNotice
} from 'routes/InformationCenter';

export const routes = [
  {
    path: '/merchantCenter/authorityManage/roleManage',  // 商户角色管理
    exact: true,
    component: RoleManage
  },
  {
    path: '/merchantCenter/authorityManage/userManage',  // 商户用户管理
    exact: true,
    component: UserManage
  },
  {
    path: '/merchantCenter/merchantManage/cooperationInfo',  // 合作信息
    exact: true,
    component: CooperationInfo
  },
  {
    path: '/merchantCenter/merchantManage/merchantInfo',   // 商户信息
    exact: true,
    component: MerchantInfo
  },
  {
    path: '/merchantCenter/merchantManage/customContract',   // 自定义合同
    exact: true,
    component: CustomContract
  },
  {
    path: '/merchantCenter/merchantManage/signCode',   // 签约码
    exact: true,
    component: SignCode
  },
  {
    path: '/merchantCenter/merchantManage/contractSett',   // 结算合同列表
    exact: true,
    component: ContractSett
  },
  {
    path: '/merchantCenter/merchantManage/rechargeReceipt',   // 充值回单列表
    exact: true,
    component: RechargeReceipt
  },
  {
    path: '/merchantCenter/merchantManage/infomationCode',   // 充值回单列表
    exact: true,
    component: InfomationCode
  },
  {
    path: '/merchantCenter/securitySetting/paymentPassword',    // 设置支付密码
    exact: true,
    component: PaymentPassword
  },
  {
    path: '/merchantCenter/securitySetting/technologyDock',    // 代付技术对接
    exact: true,
    component: TechnologyDock
  },
  {
    path: '/financialCenter/invoiceCenter/applyInvoice',     // 申请开票
    exact: true,
    component: ApplyInvoice
  },
  {
    path: '/financialCenter/invoiceCenter/invoiceInquiry',   // 开票查询
    exact: true,
    component: InvoiceInquiry
  },
  {
    path: '/financialCenter/invoiceCenter/billAddress',   // 开票地址管理
    exact: true,
    component: BillAddress
  },
  {
    path: '/financialCenter/accountFinance/accountFlow',     // 账户流水
    exact: true,
    component: AccountFlow
  },
  {
    path: '/financialCenter/accountFinance/financeBalance',   // 账户财务余额
    exact: true,
    component: FinanceBalance
  },
  {
    path: '/tradingCenter/accountRecharge/accountBalance',    // 账户余额
    exact: true,
    component: AccountBalance
  },
  {
    path: '/tradingCenter/accountRecharge/rechargeEntry',    // 充值录入
    exact: true,
    component: RechargeEntry
  },
  {
    path: '/tradingCenter/accountRecharge/rechargeRecord',    // 充值记录
    exact: true,
    component: RechargeRecord
  },
  {
    path: '/tradingCenter/entrustedSettle/bulkPayment',     // 批量付款
    exact: true,
    component: BulkPayment
  },
  {
    path: '/tradingCenter/cashsettlement/payment',     // 提现结算批量付款
    exact: true,
    component: Payment
  },
  {
    path: '/tradingCenter/entrustedSettle/paymentHistory',    // 付款记录
    exact: true,
    component: PaymentHistory
  },
  {
    path: '/tradingCenter/entrustedSettle/withdrawalRecord',    // 提现记录
    exact: true,
    component: WithdrawalRecord
  },
  {
    path: '/tradingCenter/entrustedSettle/paymentRecords',    // 批次付款记录
    exact: true,
    component: PaymentRecords
  },
  {
    path: '/tradingCenter/entrustedSettle/paymentRecords/detail/:batchNo',    // 批次付款记录  -- 付款详情
    exact: true,
    component: PaymentDetail
  },
  {
    path: '/tradingCenter/entrustedSettle/payUploadRecord',   // 付款上传记录
    exact: true,
    component: PayUploadRecord
  },
  {
    path: '/tradingCenter/cashsettlement/uploadlist',   // 提现结算上传记录
    exact: true,
    component: UploadList
  },
  {
    path: '/tradingCenter/entrustedSettle/uploadFirstTrial',    // 上传初审
    exact: true,
    component: UploadFirstTrial
  },
  {
    path: '/tradingCenter/cashsettlement/uploadtrial',    // 提现结算上传初审
    exact: true,
    component: UploadTrial
  },
  {
    path: '/tradingCenter/entrustedSettle/uploadReview',     // 上传复核
    exact: true,
    component: UploadReview
  },
  {
    path: '/tradingCenter/cashsettlement/uploadReview',     // 提现结算上传复核
    exact: true,
    component: CashUploadReview
  },
  {
    path: '/merchantCenter/professional/signRecord',     // 签约记录
    exact: true,
    component: SignRecord
  },
  {
    path: '/merchantCenter/temporarylogin/temporaryperson',    
    exact: true,
    component: TemporaryLogin
  },
  {
    path: '/merchantCenter/temporarylogin/temporaryfaillog',    
    exact: true,
    component: TemporaryFailLog
  },
  {
    path: '/merchantCenter/professional/addRecord',     // 新增记录
    exact: true,
    component: AddRecord
  },
  {
    path: '/merchantCenter/professional/addRecord/detail/:id',     // 记录详情
    exact: true,
    component: RecordDetail
  },
  {
    path: '/merchantCenter/taskManage/publishTask',     // 发布任务
    exact: true,
    component: PublishTask
  },
  {
    path: '/merchantCenter/taskManage/publishTask/detail/:type/:id',     // 发布任务 -- 详情
    exact: true,
    component: PublishTask
  },
  {
    path: '/merchantCenter/taskManage/taskList',     // 任务列表
    exact: true,
    component: TaskList
  },
  {
    path: '/merchantCenter/taskManage/taskList/recruitmentInfo/:jobCode/:jobName',     // 招募信息
    exact: true,
    component: RecruitmentInfo
  },
  {
    path: '/merchantCenter/taskManage/templateList',     // 模板列表
    exact: true,
    component: TemplateList
  },
  {
    path: '/merchantCenter/taskManage/templateList/detail/:id',     // 模板详情
    exact: true,
    component: TemplateDetail
  },
  {
    path: '/homepage/indexpage',     // 主页
    exact: true,
    component: Home
  },
  {
    path: '/merchantCenter/groupManage/subsidiaryManage',   // 子公司管理
    exact: true,
    component: SubsidiaryManage
  },
  {
    path: '/informationCenter/announceInfo/serviceNotice',   // 服务通知
    exact: true,
    component: ServiceNotice
  },
]
