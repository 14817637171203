import {formatTableData} from 'utils/util';

const USER_LIST_START = 'zb/USER_LIST_START';
const USER_LIST_SUCCESS = 'zb/USER_LIST_SUCCESS';
const USER_LIST_FAILED = 'zb/USER_LIST_FAILED';

const UPDATE_USER_START = 'zb/UPDATE_USER_START';
const UPDATE_USER_SUCCESS = 'zb/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILED = 'zb/UPDATE_USER_FAILED';

const DELETE_USER_START = 'zb/DELETE_USER_START';
const DELETE_USER_SUCCESS = 'zb/DELETE_USER_SUCCESS';
const DELETE_USER_FAILED = 'zb/DELETE_USER_FAILED';

const CREATE_USER_START = 'zb/CREATE_USER_START';
const CREATE_USER_SUCCESS = 'zb/CREATE_USER_SUCCESS';
const CREATE_USER_FAILED = 'zb/CREATE_USER_FAILED';

let initialState = {
  userList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case USER_LIST_SUCCESS:
      return {
        ...state,
        userList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getUserList(data = {}) {
  return {
    types: [USER_LIST_START, USER_LIST_SUCCESS, USER_LIST_FAILED],
    promise: (client) => client.get('user/list', {
      params: data,
    })
  };
}


export function updateUser(data = {}) {
  return {
    types: [UPDATE_USER_START, UPDATE_USER_SUCCESS, UPDATE_USER_FAILED],
    promise: (client) => client.put('user/update', {
      data: data,
    })
  };
}


export function deleteUser(data = {}) {
  return {
    types: [DELETE_USER_START, DELETE_USER_SUCCESS, DELETE_USER_FAILED],
    promise: (client) => client.delete('user/delete', {
      params: data,
    })
  };
}

export function createUser(data = {}) {
  return {
    types: [CREATE_USER_START, CREATE_USER_SUCCESS, CREATE_USER_FAILED],
    promise: (client) => client.post('user/create', {
      data: data,
    })
  };
}