const LOGIN_START = 'zb/LOGIN_START';
const LOGIN_SUCCESS = 'zb/LOGIN_SUCCESS';
const LOGIN_FAILED = 'zb/LOGIN_FAILED';

let initialState = {
  user: null,
  jwt: ''
};

const saveInfo = (data) => {
  localStorage.setItem("jwt", data.accessToken);
  localStorage.setItem("userInfo", JSON.stringify(data.user));
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      if(action.resp.data){
        saveInfo(action.resp.data);
      }
      return {
        ...state,
        jwt: action.resp.data && action.resp.data.accessToken || '',
        user: action.resp.data && action.resp.data.user || {},
      };
    default:
      return state;
  }
}

export function login(data) {
  return {
    types: [LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILED],
    promise: (client) => client.post('user/login', {
      data: data,
      hasToken: false
    })
  };
}
