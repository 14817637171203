const GET_MENU_LIST_START = 'zb/GET_MENU_LIST_START';
const GET_MENU_LIST_SUCCESS = 'zb/GET_MENU_LIST_SUCCESS';
const GET_MENU_LIST_FAILED = 'zb/GET_MENU_LIST_FAILED';

const GET_DICTIONARY_LIST_START = 'zb/GET_DICTIONARY_LIST_START';
const GET_DICTIONARY_LIST_SUCCESS = 'zb/GET_DICTIONARY_LIST_SUCCESS';
const GET_DICTIONARY_LIST_FAILED = 'zb/GET_DICTIONARY_LIST_FAILED';

const GET_TAXLAND_LIST_START = 'zb/GET_TAXLAND_LIST_START';
const GET_TAXLAND_LIST_SUCCESS = 'zb/GET_TAXLAND_LIST_SUCCESS';
const GET_TAXLAND_LIST_FAILED = 'zb/GET_TAXLAND_LIST_FAILED';

const GET_PAY_MOBILE_START = 'zb/GET_PAY_MOBILE_START';
const GET_PAY_MOBILE_SUCCESS = 'zb/GET_PAY_MOBILE_SUCCESS';
const GET_PAY_MOBILE_FAILED = 'zb/GET_PAY_MOBILE_FAILED';

const VERIFICATION_CODE_START = 'zb/VERIFICATION_CODE_START';
const VERIFICATION_CODE_SUCCESS = 'zb/VERIFICATION_CODE_SUCCESS';
const VERIFICATION_CODE_FAILED = 'zb/VERIFICATION_CODE_FAILED';

const GET_SIGNCODE_START = 'zb/GET_SIGNCODE_START';
const GET_SIGNCODE_SUCCESS = 'zb/GET_SIGNCODE_SUCCESS';
const GET_SIGNCODE_FAILED = 'zb/GET_SIGNCODE_FAILED';

const GET_GETTEMPORARYCODE_START = 'zb/GET_GETTEMPORARYCODE_START';
const GET_GETTEMPORARYCODE_SUCCESS = 'zb/GET_GETTEMPORARYCODE_SUCCESS';
const GET_GETTEMPORARYCODE_FAILED = 'zb/GET_GETTEMPORARYCODE_FAILED';

const GET_GETSERVERTYPELIST_START = 'rsd/user/GET_GETSERVERTYPELIST_START';
const GET_GETSERVERTYPELIST_SUCCESS = 'rsd/user/GET_GETSERVERTYPELIST_SUCCESS';
const GET_GETSERVERTYPELIST_FAILED = 'rsd/user/GET_GETSERVERTYPELIST_FAILED';

const RESET_STATE_SUCCESS = 'rsd/user/RESET_STATE_SUCCESS';



let initialState = {
  menuList: [],
  // menuList: [
  //   {
  //     "menu": "首页",
  //     "icon": "home",
  //     "depth": 1,
  //     "route": "/",
  //     "key": "homepage",
  //     "menuIds": [
  //       0
  //     ],
  //     "children": [
  //       {
  //         "menu": "主页",
  //         "icon": "home",
  //         "depth": 2,
  //         "route": "/homepage/indexpage",
  //         "key": "indexpage",
  //         "menuId": 0
  //       }
  //     ]
  //   },
  //   {
  //     "id": 77,
  //     "menu": "商户中心",
  //     "icon": "商户中心",
  //     "depth": 1,
  //     "route": "",
  //     "key": "merchantCenter",
  //     "menuIds": [
  //       85,
  //       86,
  //       118,
  //       87,
  //       88,
  //       111,
  //       89,
  //       90,
  //       91,
  //       92,
  //       271,
  //       272,
  //       94,
  //       811,
  //       871,
  //       881
  //     ],
  //     "children": [
  //       {
  //         "id": 80,
  //         "menu": "商户信息",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "merchantManage",
  //         "menuIds": [
  //           85,
  //           86,
  //           118,
  //           119,
  //           120
  //         ],
  //         "children": [
  //           {
  //             "id": 85,
  //             "menu": "商户信息",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/merchantManage/merchantInfo",
  //             "key": "merchantInfo",
  //             "secondKey": "merchantManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 85
  //           },
  //           {
  //             "id": 119,
  //             "menu": "合同结算列表",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/merchantManage/contractSett",
  //             "key": "contractSett",
  //             "secondKey": "merchantManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 119
  //           },
  //           {
  //             "id": 120,
  //             "menu": "充值回单列表",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/merchantManage/rechargeReceipt",
  //             "key": "rechargeReceipt",
  //             "secondKey": "merchantManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 120
  //           },
  //           {
  //             "id": 86,
  //             "menu": "合作信息",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/merchantManage/cooperationInfo",
  //             "key": "cooperationInfo",
  //             "secondKey": "merchantManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 86
  //           },
  //           {
  //             "id": 118,
  //             "menu": "签约码",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/merchantManage/signCode",
  //             "key": "signCode",
  //             "secondKey": "merchantManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 118
  //           },
  //           {
  //             "id": 121,
  //             "menu": "信息补全码",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/merchantManage/infomationCode",
  //             "key": "infomationCode",
  //             "secondKey": "merchantManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 121
  //           },
  //         ],
  //         "rootKey": "merchantCenter"
  //       },
  //       {
  //         "id": 81,
  //         "menu": "自由职业者",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "professional",
  //         "menuIds": [
  //           87,
  //           88
  //         ],
  //         "children": [
  //           {
  //             "id": 87,
  //             "menu": "签约记录",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/professional/signRecord",
  //             "key": "signRecord",
  //             "secondKey": "professional",
  //             "rootKey": "merchantCenter",
  //             "menuId": 87
  //           },
  //           {
  //             "id": 88,
  //             "menu": "新增记录",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/professional/addRecord",
  //             "key": "addRecord",
  //             "secondKey": "professional",
  //             "rootKey": "merchantCenter",
  //             "menuId": 88
  //           }
  //         ],
  //         "rootKey": "merchantCenter"
  //       },
  //       {
  //         "id": 811,
  //         "menu": "临登管理",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "temporarylogin",
  //         "menuIds": [
  //           871,
  //           881
  //         ],
  //         "children": [
  //           {
  //             "id": 871,
  //             "menu": "临登人员",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/temporarylogin/temporaryperson",
  //             "key": "temporaryperson",
  //             "secondKey": "temporarylogin",
  //             "rootKey": "merchantCenter",
  //             "menuId": 871
  //           },
  //           {
  //             "id": 881,
  //             "menu": "临登失败记录",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/temporarylogin/temporaryfaillog",
  //             "key": "temporaryfaillog",
  //             "secondKey": "temporarylogin",
  //             "rootKey": "merchantCenter",
  //             "menuId": 881
  //           }
  //         ],
  //         "rootKey": "merchantCenter"
  //       },
  //       {
  //         "id": 110,
  //         "menu": "集团管理",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "groupManage",
  //         "menuIds": [
  //           111
  //         ],
  //         "children": [
  //           {
  //             "id": 111,
  //             "menu": "子公司管理",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/groupManage/subsidiaryManage",
  //             "key": "subsidiaryManage",
  //             "secondKey": "groupManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 111
  //           }
  //         ],
  //         "rootKey": "merchantCenter"
  //       },
  //       {
  //         "id": 82,
  //         "menu": "安全设置",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "securitySetting",
  //         "menuIds": [
  //           89,
  //           90
  //         ],
  //         "children": [
  //           {
  //             "id": 89,
  //             "menu": "设置支付密码",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/securitySetting/paymentPassword",
  //             "key": "paymentPassword",
  //             "secondKey": "securitySetting",
  //             "rootKey": "merchantCenter",
  //             "menuId": 89
  //           },
  //           {
  //             "id": 90,
  //             "menu": "代付技术对接",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/securitySetting/technologyDock",
  //             "key": "technologyDock",
  //             "secondKey": "securitySetting",
  //             "rootKey": "merchantCenter",
  //             "menuId": 90
  //           }
  //         ],
  //         "rootKey": "merchantCenter"
  //       },
  //       {
  //         "id": 83,
  //         "menu": "权限管理",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "authorityManage",
  //         "menuIds": [
  //           91,
  //           92
  //         ],
  //         "children": [
  //           {
  //             "id": 91,
  //             "menu": "用户列表",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/authorityManage/userManage",
  //             "key": "userManage",
  //             "secondKey": "authorityManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 91
  //           },
  //           {
  //             "id": 92,
  //             "menu": "角色列表",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/authorityManage/roleManage",
  //             "key": "roleManage",
  //             "secondKey": "authorityManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 92
  //           }
  //         ],
  //         "rootKey": "merchantCenter"
  //       },
  //       {
  //         "id": 84,
  //         "menu": "任务管理",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "taskManage",
  //         "menuIds": [
  //           271,
  //           272,
  //           94
  //         ],
  //         "children": [
  //           {
  //             "id": 271,
  //             "menu": "模板列表",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/taskManage/templateList",
  //             "key": "templateList",
  //             "secondKey": "taskManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 271
  //           },
  //           {
  //             "id": 272,
  //             "menu": "发布任务",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/taskManage/publishTask",
  //             "key": "publishTask",
  //             "secondKey": "taskManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 272
  //           },
  //           {
  //             "id": 94,
  //             "menu": "任务列表",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/merchantCenter/taskManage/taskList",
  //             "key": "taskList",
  //             "secondKey": "taskManage",
  //             "rootKey": "merchantCenter",
  //             "menuId": 94
  //           }
  //         ],
  //         "rootKey": "merchantCenter"
  //       }
  //     ]
  //   },
  //   {
  //     "id": 78,
  //     "menu": "交易中心",
  //     "icon": "transaction",
  //     "depth": 1,
  //     "route": "",
  //     "key": "tradingCenter",
  //     "menuIds": [
  //       97,
  //       98,
  //       99,
  //       100,
  //       101,
  //       102,
  //       103,
  //       104,
  //       269
  //     ],
  //     "children": [
  //       {
  //         "id": 95,
  //         "menu": "账户充值",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "accountRecharge",
  //         "menuIds": [
  //           97,
  //           98,
  //           99
  //         ],
  //         "children": [
  //           {
  //             "id": 97,
  //             "menu": "充值录入",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/accountRecharge/rechargeEntry",
  //             "key": "rechargeEntry",
  //             "secondKey": "accountRecharge",
  //             "rootKey": "tradingCenter",
  //             "menuId": 97
  //           },
  //           {
  //             "id": 98,
  //             "menu": "充值记录",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/accountRecharge/rechargeRecord",
  //             "key": "rechargeRecord",
  //             "secondKey": "accountRecharge",
  //             "rootKey": "tradingCenter",
  //             "menuId": 98
  //           },
  //           {
  //             "id": 99,
  //             "menu": "账户余额",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/accountRecharge/accountBalance",
  //             "key": "accountBalance",
  //             "secondKey": "accountRecharge",
  //             "rootKey": "tradingCenter",
  //             "menuId": 99
  //           }
  //         ],
  //         "rootKey": "tradingCenter"
  //       },
  //       {
  //         "id": 96,
  //         "menu": "委托结算",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "entrustedSettle",
  //         "menuIds": [
  //           100,
  //           101,
  //           102,
  //           103,
  //           104,
  //           269
  //         ],
  //         "children": [
  //           {
  //             "id": 100,
  //             "menu": "批量付款",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/entrustedSettle/bulkPayment",
  //             "key": "bulkPayment",
  //             "secondKey": "entrustedSettle",
  //             "rootKey": "tradingCenter",
  //             "menuId": 100
  //           },
  //           {
  //             "id": 101,
  //             "menu": "商户上传记录",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/entrustedSettle/payUploadRecord",
  //             "key": "payUploadRecord",
  //             "secondKey": "entrustedSettle",
  //             "rootKey": "tradingCenter",
  //             "menuId": 101
  //           },
  //           {
  //             "id": 102,
  //             "menu": "上传初审",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/entrustedSettle/uploadFirstTrial",
  //             "key": "uploadFirstTrial",
  //             "secondKey": "entrustedSettle",
  //             "rootKey": "tradingCenter",
  //             "menuId": 102
  //           },
  //           {
  //             "id": 103,
  //             "menu": "上传复核",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/entrustedSettle/uploadReview",
  //             "key": "uploadReview",
  //             "secondKey": "entrustedSettle",
  //             "rootKey": "tradingCenter",
  //             "menuId": 103
  //           },
  //           {
  //             "id": 104,
  //             "menu": "批次付款记录",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/entrustedSettle/paymentRecords",
  //             "key": "paymentRecords",
  //             "secondKey": "entrustedSettle",
  //             "rootKey": "tradingCenter",
  //             "menuId": 104
  //           },
  //           {
  //             "id": 269,
  //             "menu": "付款记录",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/tradingCenter/entrustedSettle/paymentHistory",
  //             "key": "paymentHistory",
  //             "secondKey": "entrustedSettle",
  //             "rootKey": "tradingCenter",
  //             "menuId": 269
  //           }
  //         ],
  //         "rootKey": "tradingCenter"
  //       }
  //     ]
  //   },
  //   {
  //     "id": 79,
  //     "menu": "账务中心",
  //     "icon": "transaction",
  //     "depth": 1,
  //     "route": "",
  //     "key": "financialCenter",
  //     "menuIds": [
  //       106,
  //       107,
  //       273
  //     ],
  //     "children": [
  //       {
  //         "id": 105,
  //         "menu": "开票中心",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "invoiceCenter",
  //         "menuIds": [
  //           106,
  //           107,
  //           273
  //         ],
  //         "children": [
  //           {
  //             "id": 106,
  //             "menu": "申请开票",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/financialCenter/invoiceCenter/applyInvoice",
  //             "key": "applyInvoice",
  //             "secondKey": "invoiceCenter",
  //             "rootKey": "financialCenter",
  //             "menuId": 106
  //           },
  //           {
  //             "id": 107,
  //             "menu": "开票查询",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/financialCenter/invoiceCenter/invoiceInquiry",
  //             "key": "invoiceInquiry",
  //             "secondKey": "invoiceCenter",
  //             "rootKey": "financialCenter",
  //             "menuId": 107
  //           },
  //           {
  //             "id": 273,
  //             "menu": "开票地址管理",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/financialCenter/invoiceCenter/billAddress",
  //             "key": "billAddress",
  //             "secondKey": "invoiceCenter",
  //             "rootKey": "financialCenter",
  //             "menuId": 273
  //           }
  //         ],
  //         "rootKey": "financialCenter"
  //       }
  //     ]
  //   },
  //   {
  //     "id": 115,
  //     "menu": "信息中心",
  //     "icon": "mail",
  //     "depth": 1,
  //     "route": "",
  //     "key": "informationCenter",
  //     "menuIds": [
  //       117
  //     ],
  //     "children": [
  //       {
  //         "id": 116,
  //         "menu": "公告信息",
  //         "icon": "",
  //         "depth": 2,
  //         "route": "",
  //         "key": "announceInfo",
  //         "menuIds": [
  //           117
  //         ],
  //         "children": [
  //           {
  //             "id": 117,
  //             "menu": "服务通知",
  //             "icon": "",
  //             "depth": 3,
  //             "route": "/informationCenter/announceInfo/serviceNotice",
  //             "key": "serviceNotice",
  //             "secondKey": "announceInfo",
  //             "rootKey": "informationCenter",
  //             "menuId": 117
  //           }
  //         ],
  //         "rootKey": "informationCenter"
  //       }
  //     ]
  //   }
  // ],
  dictionaryList: [],
  taxLandList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MENU_LIST_SUCCESS:
      // localStorage.setItem('menuList', JSON.stringify(state.menuList));

      return {
        ...state,
        menuList: action.resp.data
      };
    case GET_DICTIONARY_LIST_SUCCESS:
      return {
        ...state,
        dictionaryList: action.resp.data
      };
    case GET_TAXLAND_LIST_SUCCESS:
      return {
        ...state,
        taxLandList: action.resp.data
      };
    case GET_PAY_MOBILE_SUCCESS:
      return {
        ...state,
      };
    case VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
      };
    case GET_SIGNCODE_SUCCESS:
      return {
        ...state,
      };
    case GET_GETTEMPORARYCODE_SUCCESS:
      return {
        ...state,
      };
    case GET_GETSERVERTYPELIST_SUCCESS:
      console.log('action-', action)
      return {
        ...state,
        serverTypeList: action.resp.data || []
      };
    // 重置某项
    case RESET_STATE_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// 获取菜单列表
export function getMenuList(data = {}) {
  data.channel = 'type2';
  return {
    types: [GET_MENU_LIST_START, GET_MENU_LIST_SUCCESS, GET_MENU_LIST_FAILED],
    promise: (client) => client.get('system/menu/list', {
      params: data,
    })
  };
}

// 获取字典表列表
export function getDictionaryList(data = {}) {
  return {
    types: [GET_DICTIONARY_LIST_START, GET_DICTIONARY_LIST_SUCCESS, GET_DICTIONARY_LIST_FAILED],
    promise: (client) => client.get('dictionary/type/list', {
      params: data,
    })
  };
}

// 获取服务商列表
export function getTaxLandList(data = {}) {
  return {
    types: [GET_TAXLAND_LIST_START, GET_TAXLAND_LIST_SUCCESS, GET_TAXLAND_LIST_FAILED],
    promise: (client) => client.get('levy/list', {
      params: data,
    })
  };
}

// 获取财务手机号
export function getPayMobile(data = {}) {
  return {
    types: [GET_PAY_MOBILE_START, GET_PAY_MOBILE_SUCCESS, GET_PAY_MOBILE_FAILED],
    promise: (client) => client.get('finance/settle/getFinanceMobile', {
      params: data,
    })
  };
}

// 校验密码并发送短信获取验证码
export function verificationCode(data) {
  return {
    types: [VERIFICATION_CODE_START, VERIFICATION_CODE_SUCCESS, VERIFICATION_CODE_FAILED],
    promise: (client) => client.post('finance/settle/verificationCode', {
      data: data,
    })
  };
}

// 获取签约小程序码
export function getSignCode(data = {}) {
  return {
    types: [GET_SIGNCODE_START, GET_SIGNCODE_SUCCESS, GET_SIGNCODE_FAILED],
    promise: (client) => client.post('qrCode/sign', {
      data: data,
    })
  };
}
//获取临登签约码
export function getTemporaryCode(data = {}) {
  return {
    types: [GET_GETTEMPORARYCODE_START, GET_GETTEMPORARYCODE_SUCCESS, GET_GETTEMPORARYCODE_FAILED],
    promise: (client) => client.get('qrCode/tempSoho', {
      data: data,
    })
  };
}
// 获取logo
export function merchantGetMerLogo(data = {}) {
  return {
    types: [GET_PAY_MOBILE_START, GET_PAY_MOBILE_SUCCESS, GET_PAY_MOBILE_FAILED],
    promise: (client) => client.get('merchant/getMerLogo', {
      params: data,
    })
  };
}
//服务类型
export const getServerTypeList = (data) => {
  return {
    types: [GET_GETSERVERTYPELIST_START, GET_GETSERVERTYPELIST_SUCCESS, GET_GETSERVERTYPELIST_FAILED],
    promise: (client) => {
      return client.get(`/contract/var/levyVarList`, {
        params: {
          ...data
        }
      });
    }
  };
};

// 重置redux
export const resetState = (payload) => {
  return {
    type: RESET_STATE_SUCCESS,
    payload
  }
}



