import AsyncLoader from 'component/AsyncLoader';

export const BulkPayment =  AsyncLoader(() => import('./BulkPayment'));
export const PaymentRecords =  AsyncLoader(() => import('./PaymentRecords'));
export const PayUploadRecord =  AsyncLoader(() => import('./PayUploadRecord'));
export const UploadFirstTrial =  AsyncLoader(() => import('./UploadFirstTrial'));
export const UploadReview =  AsyncLoader(() => import('./UploadReview'));
export const PaymentDetail =  AsyncLoader(() => import('./PaymentRecords/PaymentDetail'));
export const PaymentHistory =  AsyncLoader(() => import('./PaymentHistory'));
export const WithdrawalRecord =  AsyncLoader(() => import('./WithdrawalRecord'));
