import axios from 'axios';
import { message } from 'antd';

const methods = ['get', 'post', 'put', 'patch', 'delete'];

let production = process.env.REACT_APP_URL_TYPE === 'public';
let publicBaseUrl = window.location.origin + '/api/merchant/';
let dc = 'http://192.168.4.119:28880/api/merchant/';

// let yt = 'http://192.168.4.110:28880/api/merchant/';
let yt = 'http://218.17.157.45:60998/api/merchant/';
// let yt = 'http://192.168.1.183:28880/api/merchant/';
// let yt = 'http://192.168.1.132:28880/api/merchant/';
// let yt = 'http://218.17.157.45:60999/api/merchant/';

let rz = 'http://192.168.4.155:28880/api/merchant/';
// let zrz = 'https://pcmer.zonebonds.com/api/merchant/';
// let zrz = 'https://testmer.zonebonds.com/api/merchant/';

// let fileUrl = 'http://192.168.3.151:9998/api/';
// let fileUrl = 'http://192.168.1.183:28880/api/';
// let fileUrl = 'http://192.168.1.132:28880/api/';
let fileUrl = 'http://218.17.157.45:60999/api/';

// let zbTest = 'http://192.168.1.116:28880/api/merchant/';
// let zbTest = 'http://192.168.1.183:28880/api/merchant/';
// let zbTest = 'http://192.168.1.132:28880/api/merchant/';
let zbTest = 'http://218.17.157.45:60999/api/merchant/';

let baseUrl = production ? publicBaseUrl : yt;
export const formatUrl = (path) => {
  return baseUrl + path;
}

export const uploadUrl = (path) => {
  let frontUrl = production ? window.location.origin + '/api/' : fileUrl;
  return frontUrl + path;
}

class ApiClient {
  constructor() {
    methods.forEach(method =>
      this[method] = (url, { params, data, hasToken = true, headers, downloadFile, uploadFile = false } = {}) => new Promise((resolve, reject) => {
        // 携带token
        let jwt = '';
        let headerInfo = {};
        if (!hasToken) {
          jwt = '';
        } else {
          jwt = localStorage.getItem('jwt');
          headerInfo["Authorization"] = jwt ? 'Bearer ' + jwt : null;
        }
        // 配置请求头信息,配置Content-Type信息
        let contentType = '';
        if (method === 'post' || method === 'put' || method === 'patch') {
          contentType = 'application/json';
        } else {
          contentType = 'application/x-www-form-urlencoded';
        }
        headerInfo["Content-Type"] = contentType;
        if (headers) {
          for (let key in headers) {
            headerInfo[key] = headers[key];
          }
        }

        if (downloadFile) {
          axios({
            method: method,
            url: formatUrl(url),
            params: params,
            data: data,
            headers: headerInfo,
            responseType: 'blob'
          }).then((response) => {
            let blob = new Blob([response.data]);
            let downloadElement = document.createElement('a');
            let href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = downloadFile.name;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
            resolve(response.data);
          }).catch(error => {
            reject(error);
            message.error("文件下载失败，请重试");
          })
        } else {
          // axios 请求接口
          let axiosParam = {
            method: method,
            url: formatUrl(url),
            params: params,
            data: data,
            headers: headerInfo,
          }
          axios(axiosParam)
            .then((response) => {
              if (response.status === 200) {
                const { code, message: _message } = response.data;
                if (code === 200) {
                  resolve(response.data);
                } else if (code === 401) {
                  message.error('登录状态已过期，请重新登录', 2, () => {
                    localStorage.clear();
                    window.location.href = window.location.origin + window.location.search;
                  })
                } else {
                  if (_message) message.error(_message || '请求失败，请再尝试', 2);
                  reject(response.data);
                }
              } else {
                if (response.status === 500) {
                  message.error('服务器连接失败，请耐心等待', 2);
                } else {
                  console.error();
                }
                reject();
              }
            })
            .catch((error) => {
              console.error(error);
              message.error("Network Error");
            })
        }
      })
    )
  }
}

export default new ApiClient();

