import {formatTableData} from 'utils/util';

const PAYUPLOADRECORD_LIST_START = 'zb/PAYUPLOADRECORD_LIST_START';
const PAYUPLOADRECORD_LIST_SUCCESS = 'zb/PAYUPLOADRECORD_LIST_SUCCESS';
const PAYUPLOADRECORD_LIST_FAILED = 'zb/PAYUPLOADRECORD_LIST_FAILED';

const EXPORT_MERPLATRECORD_START = 'zb/EXPORT_MERPLATRECORD_START';
const EXPORT_MERPLATRECORD_SUCCESS = 'zb/EXPORT_MERPLATRECORD_SUCCESS';
const EXPORT_MERPLATRECORD_FAILED = 'zb/EXPORT_MERPLATRECORD_FAILED';

let initialState = {
  payUploadList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PAYUPLOADRECORD_LIST_SUCCESS:
      return {
        ...state,
        payUploadList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case EXPORT_MERPLATRECORD_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getPayUploadList(data = {}) {
  return {
    types: [PAYUPLOADRECORD_LIST_START, PAYUPLOADRECORD_LIST_SUCCESS, PAYUPLOADRECORD_LIST_FAILED],
    promise: (client) => client.get('finance/settle/list', {
      params: data,
    })
  };
}

// 导出excel
export function exportMerPlatRecord(data = {}) {
  return {
    types: [EXPORT_MERPLATRECORD_START, EXPORT_MERPLATRECORD_SUCCESS, EXPORT_MERPLATRECORD_FAILED],
    promise: (client) => client.get('export/merPlatRecord', {
      params: data,
      downloadFile: {name: '商户上传记录.xlsx'}
    })
  };
}
